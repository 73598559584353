@import '../../index.scss';

.cart-header {
    font-family: 'Open Sans', sans-serif;
    font-size: xx-large;
    font-weight: bold;
    margin-bottom: 2em;

    &.empty {
        height: 40vh;
        margin: 1em;
        text-align: center;
    }
}

.cart-products-container {
    width: 50%;
    min-height: 47vh;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 1480px) {
        width: 60%;
        transition: all 0.3s ease;
    }

    @media screen and (max-width: 1190px) {
        width: 70%;
        transition: all 0.3s ease;
    }

    @media screen and (max-width: 1020px) {
        width: 80%;
        transition: all 0.3s ease;
    }

    @media screen and (max-width: 890px) {
        width: 90%;
        transition: all 0.3s ease;
    }
    
    .cart-products-items-wrapper {
        width: 100%;
    }

    .cart-total-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 2em;
        font-size: x-large;
    }

    .checkout-btn {
        @include reset-button-style;
            color: white;
            background-color: #D7C724;
            margin-top: 1em;
            margin-bottom: 3em;
            font-family: 'Open Sans', sans-serif;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
            transition: box-shadow 0.3s ease;
    
            @media screen and (max-width: 460px) {
                padding: 1em 1.3em;
                transition: padding 0.5s ease;
            }
    
            &:hover {
                box-shadow: rgba(0, 0, 0, 0.5) 0px 64px 75px, rgba(0, 0, 0, 0.22) 0px -16px 40px, rgba(0, 0, 0, 0.22) 0px 6px 8px, rgba(0, 0, 0, 0.27) 0px 16px 18px, rgba(0, 0, 0, 0.19) 0px -5px 7px;
                transform: scale(1.1);
            }
    }
}