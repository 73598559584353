.reviewItem-wrapper {
    font-family: 'Open Sans', sans-serif;
    .header {
        display: flex;
        margin-bottom: 1em;
        .header-img {
            width: 4em;
            height: 4em;
        }
        .header-meta {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: bold;
            margin-left: 1em;
            .header-name {
                color: #238DC1;
            }
            .header-stars {
                color: #f9c02c;
            }
        }
    }
    .body {
        .body-text {
            line-height: 1.5em;
        }
        .body-ago {
            margin-top: 0.5em;
        }
    }
}