@import '../../index.scss';

.tiles-container {
    padding-top: 2em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;

    .tiles-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 400px;
        width: 70%;
    
        @media screen and (max-width: 1400px) {
            width: 75%;
            transition: all 0.8s linear;
        }
    
        @media screen and (max-width: 1260px) {
            width: 85%;
            transition: all 0.8s linear;
        }
    
        @media screen and (max-width: 1110px) {
            width: 95%;
            transition: all 0.8s linear;
        }
    
        @media screen and (max-width: 830px) {
            grid-template-columns: 1fr;
            grid-template-rows: 300px;
            transition: all 0.8s linear;
            width: 100%;
            // order: -1 enables tiles.img to be positioned before .tiles-price
            .tiles-img {
                order: -1;
                transition: all 0.8s linear;
            }
        }
    
        @media screen and (max-width: 540px) {
            grid-template-rows: 350px;
        }
    
        @media screen and (max-width: 460px) {
            grid-template-rows: 400px;
        }
    
        @media screen and (max-width: 330px) {
            grid-template-rows: 450px;
        }
    
        @media screen and (max-width: 300px) {
            grid-template-rows: 500px;
        }
    
    }

    .tiles-img {
        perspective: 1000px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        //
        cursor: pointer;
        position: relative;
        display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            transition: all 0.8s linear;
            //transform-style: preserve-3d;
            //transition: transform 0.3s;
            //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    
        .flip-card-front, 
        .flip-card-back {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            box-sizing: border-box;
            padding: 2em;
            //
            transition: transform 0.8s linear;
        }
        
        .flip-card-front {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            color: white;
            //
            z-index: 2;
    
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 5px;
            }
    
            &.poa {
                background-image: url('../../assets/images/poa-owner-support.jpg');
            }
    
            &.t-issuance {
                background-image: url('../../assets/images/tawtheeq-issuance.png');
            }
    
            &.t-assistance {
                background-image: url('../../assets/images/tawtheeq-assistance.png');
            }
    
            &.handover {
                background-image: url('../../assets/images/property-handover.jpg');
            }
    
            &.golden-visa {
                background-image: url('../../assets/images/golden-visa.png');
            }
        }
        
        .flip-card-back {
            transform: rotateY(180deg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            color: black;
    
            &.poa {
                background-image: url('../../assets/images/poa-owner-support.jpg');
            }
    
            &.t-issuance {
                background-image: url('../../assets/images/tawtheeq-issuance.png');
            }
    
            &.t-assistance {
                background-image: url('../../assets/images/tawtheeq-assistance.png');
            }
    
            &.handover {
                background-image: url('../../assets/images/property-handover.jpg');
            }
    
            &.golden-visa {
                background-image: url('../../assets/images/golden-visa.png');
            }
    
            @media screen and (max-width: 960px) {
                font-size: small;
                transition: all 0.8s linear;
            }
    
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(229, 228, 226, 0.8);
                z-index: -1;
                border-radius: 5px;
            }
    
            .flip-card-back-padding {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
    
            h1 {
                font-size: x-large;
            }
    
            ul {
                margin: 0;
            }
        }
    
        h1 {
            z-index: 1;
            font-weight: lighter;
        }
    }
    ////////////////////////////
    .tiles-img.flipped .flip-card-front {
        transform: rotateY(190deg);
    }
    
    .tiles-img.flipped .flip-card-back {
        transform: rotateY(0deg);
    }


    .tiles-img:hover .flip-card-front {
        transform: rotateY(180deg);
    }
    
    .tiles-img:hover .flip-card-back {
        transform: rotateY(0deg);
    }
    //////////////////////////////////
    .flip-card-back-bottom {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
    
    .document-button-wrapper {
        z-index: 1;
    
        button {
            @include reset-button-style;
            color: white;
            font-family: 'Open Sans', sans-serif;
        }
    
        button:hover {
            cursor: pointer;
            transform: translateY(-0.25em);
            box-shadow: 0 0.5em 0.5em -0.4em $color2;
        }
    }    
    
    .tiles-price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        p {
            font-size: x-large;
        }
    
        .price-button-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            button {
                z-index: 1;
                @include reset-button-style;
                color: white;
                background-color: #238DC1;
                margin-top: 1em;
                margin-bottom: 2em;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                transition: box-shadow 0.3s ease;
        
                @media screen and (max-width: 460px) {
                    padding: 1em 1.3em;
                    transition: padding 0.5s ease;
                }
        
                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.5) 0px 64px 75px, rgba(0, 0, 0, 0.22) 0px -16px 40px, rgba(0, 0, 0, 0.22) 0px 6px 8px, rgba(0, 0, 0, 0.27) 0px 16px 18px, rgba(0, 0, 0, 0.19) 0px -5px 7px;
                    transform: scale(1.1);
                }
            }
    
            .download-brochure {
                margin-top: 0;
                background-color: #39a5af;
                padding-left: 2em;
                padding-right: 2.5em;
            }
        }
    }
}