@import '../../index.scss';

.about-main-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.2;
    width: 55%;

    
    @media screen and (max-width: 1182px) {
        width: 75%;
        transition: all 0.5s ease;
    }

    .head-container {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 645px) {
            flex-direction: column;
            transition: all 0.5s ease;
        }

        .text-container {
            // flex: 1;
    
            h1 {
                margin-top: 2em;
                font-weight: 500;
                font-size: 60px;

                @media screen and (max-width: 991px) {
                    font-weight: 400;
                    font-size: 40px;
                    transition: all 0.5s ease;
                }
            }
    
            p {
                line-height: 1.5;
            }
        }
    
        .sphere-container {
            padding: 8em 2em 0em 2em;
            display: flex;
            justify-content: center;
            align-items: center; 

            @media screen and (max-width: 1340px) {
                padding: 8em 1em 0em 1em;
                transition: all 0.5s ease;
            }

            @media screen and (max-width: 645px) {
                padding: 0em;
                transition: all 0.5s ease;
            }
            img {
                height: 400px;
                width: 400px;

                @media screen and (max-width: 991px) {
                    height: 300px;
                    width: 300px;
                    transition: all 0.5s ease;
                }

                @media screen and (max-width: 645px) {
                    height: 200px;
                    width: 200px;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    h1 {
        font-weight: 500;
        font-size: 60px;

        @media screen and (max-width: 991px) {
            font-size: 40px;
            font-weight: 400;
            transition: all 0.5s ease;
        }
    }

    .services-container {
        padding-top: 2em;
        padding-bottom: 5em;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .grid-row {
            // display: flex;
            // margin-top: 3em;
            // gap: 2em;

            @media screen and (max-width: 900px) {
                flex-direction: column;
                transition: all 0.5s ease;
            }

            .img-container {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                @media screen and (max-width: 900px) {
                    order: -1;
                    transition: all 0.5s ease;
                }

                img {
                    height: 400px;
                    width: 500px;

                    @media screen and (max-width: 1350px) {
                        height: 300px;
                        width: 400px;
                        transition: all 0.5s ease;
                    }

                    @media screen and (max-width: 1225px) {
                        height: 200px;
                        width: 300px;
                        transition: all 0.5s ease;
                    }

                    @media screen and (max-width: 900px) {
                        height: 350px;
                        width: 450px;
                        transition: all 0.5s ease;
                    }

                    @media screen and (max-width: 645px) {
                        height: 200px;
                        width: 300px;
                        transition: all 0.5s ease;
                    }
                }
            }

            .text-container {
                flex: 1;
                
                @media screen and (max-width: 1225px) {
                    flex: 2;
                    transition: all 0.5s ease;
                }
                h2 {
                    font-size: 40px;
                    font-weight: normal;

                    @media screen and (max-width: 991px) {
                        font-size: 30px;
                        transition: all 0.5s ease;
                    }
                }

                p {
                    line-height: 1.5;
                }

                button {
                    @include reset-button-style;
                    margin: 0;
                    margin-top: 1em;
                    padding: 1em 1.8em;
                    border-radius: 100px;
                    background-color:rgba(57, 165, 175, 0.9);
                    font-family: inherit;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                    transform: scale(1);
                    display: inline-block;

                    &:hover {
                        transform: scale(1.1, 1);
                    }
                }
            }
        }
    }

    h2 {
        font-size: 40px;
        font-weight: normal;
    }

    .team-members-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 4em;
    }

    button {
        @include reset-button-style;
        margin: 0;
        margin-top: 1em;
        margin-bottom: 3em;
        padding: 1em 5em;
        border-radius: 100px;
        background-color: $colorM;
        font-family: inherit;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        transform: scale(1);
        display: inline-block;

        &:hover {
            transform: scale(1.1, 1);
        }
    }

    .circle-container {
        width: 100vw;
        overflow: hidden;
        position: relative;
        .circle{
            border-radius: 50%;
            background-color: $colorP;
            width: 1000px;
            height: 1000px;
            display: flex;
            gap: 5em;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            position: relative;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
    
            .together-img {
                img {
                    height: 300px;
                    width: auto;
                    object-fit: cover; 
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                    @media screen and (max-width: 545px) {
                        height: 200px;
                        transition: all 0.5s ease;
                    }
                }
            }
            .socials {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 400px;
                text-align: center;
                color: white;

                @media screen and (max-width: 430px) {
                    width: 300px;
                    transition: all 0.5s ease;
                }

                p {
                    line-height: 1.6;
                    margin-bottom: 30px;
                }

                .social-icons {
                    width: 60%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
            }
        }
    }
}