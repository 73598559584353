
.articleItem-container, .article-img {
    margin: auto;
    width: 720px;
    font-family: 'Open Sans', sans-serif;

    @media screen and (max-width: 769px) {
        width: 600px;
    }
    @media screen and (max-width: 680px) {
        width: 500px;
    }
    @media screen and (max-width: 540px) {
        width: 400px;
    }
    @media screen and (max-width: 430px) {
        width: 300px;
    }
    @media screen and (max-width: 320px) {
        width: 200px;
    }

    .article-title {
        font-size: 285%;
        font-weight: 600;

        @media screen and (max-width: 430px) {
            font-size: 200%;
        }
        @media screen and (max-width: 320px) {
            font-size: 150%;
        }
    }
    .article-body {
        color: rgb(67,67,67);
        line-height: 2.2em;
        font-size: large;

        @media screen and (max-width: 320px) {
            font-size: medium;
        }
        h3 {
            color: black;
        }
        .quote {
            border-left: 4px solid rgb(86,104,122);
            padding-left: 1em;
            color: rgb(86,104,122);
            font-size: x-large;

            @media screen and (max-width: 320px) {
                font-size: large;
            }
        }
    }
    .article-img {
        width: 720px;

        @media screen and (max-width: 769px) {
            width: 600px;
        }

        @media screen and (max-width: 680px) {
            width: 500px;
        }

        @media screen and (max-width: 540px) {
            width: 400px;
        }

        @media screen and (max-width: 430px) {
            width: 300px;
        }

        @media screen and (max-width: 320px) {
            width: 200px;
        }
    }
}