@import '../../index.scss';

@keyframes zoomingBackground {
    0% {
      background-size: 100% 100%;
    }
    50% {
      background-size: 120% 120%;
    }
    100% {
      background-size: 100% 100%;
    }
}

.home-container {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
}

.home-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65em;
    z-index: 1;
    background-image: url('../../assets/images/Home_image_resized.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.6);
    animation: zoomingBackground 10s ease-in-out infinite;

    @media screen and (max-width: 850px) {
        animation: none;
        transition: all 0.5s ease;
    }
}  

.home-image:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65em;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.6);
}

@keyframes colorTransition {
    0% {
      background-color: $colorT;
    }
    50% {
      background-color: $colorM;
    }
    100% {
        background-color: $colorP;
    }
}

// POP UP closed style
.popup-button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50vh;
    background-color: $colorT;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999;
    border-radius: 0 8px 8px 0;
    animation: colorTransition 2s infinite alternate;

    @media screen and (max-height: 530px) {
        width: 40px;
        transition: all 0.5s ease;
    }
}
 
.popup-button-text {
    writing-mode: vertical-lr;
    transform: rotate(360deg);
    color: white;
    font-size: x-large;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

    @media screen and (max-height: 530px) {
        font-size: medium;
        transition: all 0.5s ease;
    }
}

// POP UP open style
.popup-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 20%;
    height: 50%;
    background: rgba(60, 164, 175, 0.5);
    display: flex;
    justify-content: center;
    z-index: 998;
    border-radius: 0 15px 15px 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;

    &.open {
        opacity: 1;
        visibility: visible;
    }

    @media screen and (orientation: landscape) {
        background: rgba(60, 164, 175, 0.9);
        transition: background-color 0.5s ease;
    }

    @media screen and (max-width: 1195px) {
        width: 30%;
        transition: width 0.5s ease;
    }

    @media screen and (max-width: 915px) {
        width: 40%;
        transition: width 0.5s ease;
    }

    @media screen and (max-width: 770px) {
        background: rgba(60, 164, 175, 0.9);
        width: 50%;
        transition: width 0.5s ease, background 0.5s ease;
    }

    @media screen and (max-width: 375px) {
        width: 60%;
        transition: width 0.5s ease;
    }

    @media screen and (max-height: 570px) {
        height: 70%;
        transition: height 0.5s ease;
    }

    @media screen and (max-height: 560px) {
        height: 60%;
        transition: height 0.5s ease;
    }

    @media screen and (max-height: 550px) {
        height: 65%;
        transition: height 0.5s ease;
    }

    @media screen and (max-height: 410px) {
        height: 70%;
        transition: height 0.5s ease;
    }
}

.popup-close-icon {
    position: absolute;
    right: 0;
    color: $color1;
    cursor: pointer;
    font-size: 30px;
    font-weight: 100;
    font-family: inherent;
    margin: 0.3em 0.5em;
}

.popup-text {
    color: $color1;
    font-size: x-large;
    font-weight: 600;
    text-align: center;
    padding: 1.5em;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

    h1 {
        font-size: xx-large;
        margin-top: 1em;

        @media screen and (max-height: 750px) {
            font-size: large;
            transition: font-size 0.5s ease;
        }
    }

    span {
        color: $color2;
    }

    button {
        @include reset-button-style;
        color: white;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        transition: box-shadow 0.5s ease;
    }

    button:hover {
        cursor: pointer;
        @include hover-btn;
    }

    @media screen and (max-height: 875px) {
        font-size: medium;
        transition: font-size 0.5s ease;
    }

    @media screen and (max-width: 535px) {
        font-size: medium;
        transition: font-size 0.5s ease;
    }
}

.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999;
 
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
}

.home-services {
    position: relative;
    top: 65em;
    left: 0;
    margin-top: 2em;
    height: calc(100vh - 65em);
    width: 100%;

    .home-services-caption {
        line-height: 1.5em;
    }

    button {
        @include reset-button-style;
        color: white;
        background-color: #D7C724;
        margin-top: 1em;
        margin-bottom: 2em;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        transition: box-shadow 0.3s ease;

        @media screen and (max-width: 460px) {
            padding: 1em 1.3em;
            transition: padding 0.5s ease;
        }
    }

    button:hover {
        box-shadow: rgba(0, 0, 0, 0.5) 0px 64px 75px, rgba(0, 0, 0, 0.22) 0px -16px 40px, rgba(0, 0, 0, 0.22) 0px 6px 8px, rgba(0, 0, 0, 0.27) 0px 16px 18px, rgba(0, 0, 0, 0.19) 0px -5px 7px;
        transform: scale(1.1);
    }
}

.btns-container {
    display: inline-grid;
    grid-template-columns: auto auto;
    gap: 1em;

    @media screen and (max-width: 300px) {
        grid-template-columns: auto;
        gap: 0;
        transition: all 0.5s ease;
    }
}

.home-services-content {
    opacity: 0;
    margin-left: 5em;
    margin-right: 5em;
    margin-bottom: 1em;

    h1 {
        letter-spacing: 2px;
    }
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
.fade-in {
    opacity: 1;
    animation: fadeIn 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

.first-service-img {
    background-image: url('../../assets/images/img_3.jpg');
    @include service-img-style;
    margin-bottom: 3em;
    aspect-ratio: 100 / 20;
}

.second-service-img {
    background-image: url('../../assets/images/img_2.jpg');
    @include service-img-style;
    margin-bottom: 3em;
    aspect-ratio: 100 / 20;
}

.third-service-img {
    background-image: url('../../assets/images/img_6.jpg');
    @include service-img-style;
    margin-bottom: 0;
    aspect-ratio: 100 / 20;
}