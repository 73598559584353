@import '../../../../index.scss';

.owner-support-item-container {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1em;
    margin: 2em;
    margin-bottom: 6em;
    flex-wrap: wrap; 
    transition: all 0.3s ease-in-out;
    font-family: 'Open Sans', sans-serif;

    @media screen and (max-width: 400px) {
        width: 90%;
        transition: all 0.5s ease;
    }

    .table-column {
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex: 1;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        transition: all 0.3s ease-in-out;
        background-color: #e5e4e2;
        min-width: 250px;

        @media screen and (max-width: 1430px) {
            min-width: 350px;
            transition: all 0.5s ease;
        }

        @media screen and (max-width: 895px) {
            min-width: 300px;
            transition: all 0.5s ease;
        }

        .table-column-everything {
            color: #238DC1;
            margin-bottom: 1em;
            font-weight: bolder;
        }

        .table-column-header {
            background: rgb(35,141,193);
            background: linear-gradient(90deg, rgba(35,141,193,1) 50%, rgba(229,228,226,1) 100%);
            color: white;
            width: 100%;
            height: 275px;

            border-radius: 100% 0% 100% 0% / 44% 43% 57% 56%;
            padding-top: 3em;
            padding-bottom: 1em;
            margin-top: 1em;
            letter-spacing: 0.2em;

            .package-name {
                letter-spacing: 0.2em;
                margin-bottom: 0.5em;
                font-size: x-large;
                font-weight: bold;
                color: $colorM;
                font-family: 'Tilt Prism', cursive;
            }

            &.lite {
                margin-bottom: 2.4em;
            }

            img {
                width: 5em;
                height: 5em;
            }
        }
        .table-column-body {
            background-color: #e5e4e2;
            padding: 0 1em;
            display: flex;
            flex-direction: column;
            gap: 2em;
            .table-row-item {
                height: 3.5em;
                font-weight: 500;
                & p:first-child {
                    font-weight: bold;
                    margin-bottom: 0.1em;
                }

                & p:nth-child(2) {
                    margin-top: 0.2em;
                    font-size: small;
                }
            }
        }

        .table-column-footer {
            margin: 2em 0;
            .table-column-price {
                padding: 1em 3em;
                font-size: large;
                color: #238DC1;
            }
            button {
                @include reset-button-style;
                background-color: $colorM;
                color: white;
                margin-top: 0.5em;
                margin-bottom: 0.5em;

                &:hover {
                    @include hover-btn;
                }
            }
        }

        .ribbon {
            width: 150px;
            height: 150px;
            overflow: hidden;
            position: absolute;
            top: -10px;
            right: -10px;
            z-index: 1;
            
            &:before, &:after {
                position: absolute;
                z-index: -1;
                content: '';
                display: block;
                border: 5px solid #2e858f;
                border-top-color: transparent;
                border-right-color: transparent;
            }

            &:before {
                top: 0;
                left: 0;
            }

            &:after {
                bottom: 0;
                right: 0;
            }

            span {
                font-size: small;
                position: absolute;
                display: block;
                width: 225px;
                padding: 15px 0;
                background-color: #39a5af;
                box-shadow: 0 5px 10px rgba(0,0,0,.1);
                color: #fff;
                font: 700 18px/1 'Domine', serif;;
                text-shadow: 0 1px 1px rgba(0,0,0,.2);
                text-transform: uppercase;
                text-align: center;
                left: -25px;
                top: 30px;
                transform: rotate(45deg);
            }
        }

        &.proper {
            .ribbon:before, .ribbon:after {
                border: 5px solid #cf6c6a;
            }

            span {
                background-color: #E4A9A8;

            }
        }

        &:hover {
            box-shadow: 0 0 10px 2px #ff9900;
            transform: scale(1.01);
        }
    }
}

.poa-note {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 160%;
    width: 50%;
    padding-left: 1em;
    padding-right: 1em;
    background-color: #89d1f5;

    & p {
        border: 1px solid white;
        padding: 1em 1em;
    }

    @media screen and (max-width: 1430px) {
        position: relative;
        width: 100%;
        transition: all 0.5s ease;
    }
}
