@import '../../index.scss';

.document-form-header {
  font-size: x-large;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1em;
  letter-spacing: 0.1em;
}

.document-form-caption {
  font-size: large;
  margin-bottom: 1em;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
}

.document-form-container {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-left: 3em;
  margin-right: 3em;
  min-height: 45vh;
  font-family: 'Open Sans', sans-serif;
  width: 35em;

  @media screen and (max-width: 600px) {
    width: 20em;
    transition: all 0.5s ease;
}

@media screen and (max-width: 350px) {
  width: 15em;
  transition: all 0.5s ease;
}

  .form-inputs {
    display: flex;
    flex-direction: column;

    input, textarea {
      padding: 1em;
      border-radius: 15px;
      margin: 0.5em;
      border: 2px solid #238DC1;
      font-family: 'Open Sans', sans-serif;
    }

    textarea {
      height: 40px;
    }

    .disabled {
      background-color: rgb(219, 219, 219);
    }

    .email-error {
      font-size: small;
      color: #eb9594;
      text-align: center;
    }
  }

  .reset-container {
    display: flex;
    justify-content: center;
    align-items: center;

    a, button {
      margin: 1em;
      cursor: pointer;
      color: purple;
      font-family: 'Open Sans', sans-serif;
    }

    button {
      border: none;
      background: none;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-decoration: underline;
    }
  }

  .btn-upload-docs {
    @include reset-button-style;
    border: 2px solid #CBC82D;
    font-family: 'Open Sans', sans-serif;

    &:hover {
      @include hover-btn;
    }
  }
}
    
  .file-input {
    display: none;
  }
