@import '../../../index.scss';

.navbar-container-wrapper {
    position: fixed;
    width: 100%;
    z-index: 5;
}

.navbar-container {
    width: 100%;
    height: 3em;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 100;
    font-size: small;
    letter-spacing: 0.2em;
    position: absolute;

    .cart-img {
        top: 3px;
        right: 0;
    }

    .burger-img {
        top: 9px;
        left: 15px;
    }

    img {
        position: absolute;
        width: 1.5em;
        height: 1.5em;
    }

    .navbar-container-cross {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 30px;
        font-weight: 100;
        font-family: inherent;
        color: #CBC82D;
        z-index: 1;

        position: absolute;
        top: 2px;
        left: 15px;
    }

    .navbar-burger {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hidden {
        visibility: hidden;
    }

    .navbar-sections {
        display: flex;
        justify-content: space-around;
        align-items: center;
        visibility: visible;
        margin-top: 10px;
        margin-left: 5em;
        margin-right: 5em;
    
        @media screen and (max-width: 410px) {
            width: 88%;
            transition: all 0.3s ease;
        }

        @media screen and (min-width: 900px) {
            width: 700px;
            margin: 10px auto;
            transition: all 0.3s ease;
        }
        
        //visibility:hidden for NavBar sections on max-width 768px
        @media screen and (max-width: 768px) {
            visibility:hidden;
        }
        .active {
            color: $colorM;
            font-weight: bold;
            text-shadow: $colorT 5px 0 10px;
        }
    }

    .navbar-section:hover {
        cursor: pointer;
    }

    .nav-logo {
        background-image: url('../../../assets/images/tpm_logo.png');
        width: 3em;
        height: 2em;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 2px;
        right: 20px;
        top: 5px;

        @media screen and (max-width: 768px) {
            visibility:hidden;
        }
    }
}

.navbar-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 15px;

    &:hover{
        cursor: pointer;
    }

    .navbar-cart-qty-container{
        position: absolute;
        top: 5px;
        right: 10px;
        color: black;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 60%;
        padding-left: 0.2em;
        padding-right: 0.1em;
        z-index: 6;
    }
}

.navbar-container-expanded {
    display: flex;
    flex-direction: column;
    align-items:flex-start;

    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: small;
    letter-spacing: 0.2em;

    padding-top: 40px;
    padding-left: 40px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    animation: navbarExpand 0.5s ease forwards;

    .navbar-section-expanded {
        margin-bottom: 10px;
    }
}

.navbar-container-expanded.animate {
    top: calc(100% + 10px);
  }

@keyframes navbarExpand {
    0% {
      top: -100%;
    }
    100% {
      top: 0;
    }
}