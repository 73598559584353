@import '../../../index.scss';

.footer-container {
    position: relative;
    display: flex;
    z-index: -1;
    width: 100%;
    background-color: #1e5258;
    display: flex;

    @media screen and (max-width: 520px) {
        justify-content: center;
        transition: all 0.5s ease;
        flex-direction: column;
    }
}

.footer-content {
    display: flex;
    justify-content: space-around;
    padding: 2em 4em;
    font-family: 'Barlow Semi Condensed', sans-serif;
    color: white;
    text-align: left;
    flex-wrap: wrap;

    @media screen and (max-width: 520px) {
        flex-direction: column;
        text-align: center;
        transition: all 0.5s ease;
    }

    h1 {
        font-size: large;
    }

    .footer-socials {
        display: flex;
        justify-content:space-around;
        align-items: center;
        padding-bottom: 2em;
    }
    img {
        width: 1.5em;
        height: 1.5em;
        object-fit: contain;
    }

    img:hover {
        cursor: pointer;
    }
}

.footer-content-block:nth-child(2) {
    margin-left: 6em;
    margin-right: 6em;

    .privacy-policy {
        text-decoration: none;
        color: #CBC82D;
        font-weight: 600;
        &:hover {
            color: white;
        }
    }

    @media screen and (max-width: 805px) {
        margin-left: 5em;
        margin-right: 5em;
        transition: all 0.5s ease;
    }
    @media screen and (max-width: 705px) {
        margin-left: 4em;
        margin-right: 4em;
        transition: all 0.5s ease;
    }
    @media screen and (max-width: 605px) {
        margin-left: 1em;
        margin-right: 1em;
        transition: all 0.5s ease;
    }
    @media screen and (max-width: 530px) {
        margin-left: 0;
        margin-right: 0;
        transition: all 0.5s ease;
    }
}

.footer-content-block:nth-child(4) {
    margin-left: 6em;
    margin-right: 6em;

    @media screen and (max-width: 805px) {
        margin-left: 5em;
        margin-right: 5em;
        transition: all 0.5s ease;
    }
    @media screen and (max-width: 705px) {
        margin-left: 4em;
        margin-right: 4em;
        transition: all 0.5s ease;
    }
    @media screen and (max-width: 605px) {
        margin-left: 1em;
        margin-right: 1em;
        transition: all 0.5s ease;
    }
    @media screen and (max-width: 530px) {
        margin-left: 0;
        margin-right: 0;
        transition: all 0.5s ease;
    }
}

.footer-content-block {
    .crompton-container {
        padding-top: 0.5em;

        background: rgb(229,228,226);
        background: radial-gradient(circle, rgba(229,228,226,1) 43%, rgba(30,82,88,1) 100%);
        border-radius: 70% 0% 70% 0% / 44% 43% 57% 56%;
        border: 1px solid rgba(30,82,88,1);

        img {
            width: 200px;
            height: 100px;

            &:hover {
                cursor: auto;
            }
        }
    }

    .storeImg {
        width: 200px;
        height: 100px;

        &:first-of-type {
            margin-right: 2em;

            @media screen and (max-width: 520px) {
                margin-right: 0;
                transition: all 0.5s ease;
            }
        }
    }

    .google-container {

        img {
            width: 200px;
            height: 100px;
        }
    }
}

.footer-link {
    text-decoration: none;
    color: white;
}

