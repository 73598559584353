@import '../../index.scss';

.articles-container {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    justify-content: space-around;
    align-items: center;
    gap: 2em;
    margin-bottom: 3em;
    margin-top: 3em;
    .article-card {
        width: 400px;
        position: relative;
        background-color: rgb(236, 236, 236);
        border-radius: 10px;

        @media screen and (max-width: 425px) {
            width: 300px;
            transition: all 0.5s ease;
        }
    
        .card-img {
            height: 130px;
            width: 100%;
            border-radius: 10px;

            @media screen and (max-width: 425px) {
                height: 100px;
                transition: all 0.5s ease;
            }
        }
        .card-title {
            font-size: 22px;
            font-weight: bold;
            margin: 0em 0.5em 0.5em 0.5em;
            height: 5em;

            
            @media screen and (max-width: 510px) {
                height: 6.5em;
                transition: all 0.5s ease;
            }
        }
        .card-caption {
            margin-left: 0.5em;
            padding-bottom: 0.5em;
        }
        &:hover {
            &::before {
                content: 'Click to read . . .';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.7);
                border-radius: 10px;
                top: 0;
                left: 0;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                font-size: 1.5em;
            }
        }
    }
}
