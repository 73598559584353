.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .cards-container-item {
        perspective: 1000px; // Important for 3D flipping effects
        width: 350px;
        height: 350px;
        margin: 1em;
        cursor: pointer;
        position: relative;
    }
    
    .front, .back {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden; // Essential for hiding the flipped side
        transition: transform 0.8s linear;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        color: #fff;

        h3 {
            font-weight: normal;
        }
        p {
            line-height: 1.5rem;
            font-size: 15px;
        }
    }
    
    .front {
        z-index: 2;
        background-color: #238DC1;

        img {
            width: 140px;
            height: 140px;
        }
    }
    
    .back {
        background-color: #1d75a1;
        transform: rotateX(190deg);
        p {
            margin-left: 1rem;
            margin-right: 1rem;
            text-align: center;
        }
    }
    
    .cards-container-item.flipped .front {
        transform: rotateX(190deg);
    }
    
    .cards-container-item.flipped .back {
        transform: rotateX(0deg);
    }


    .cards-container-item:hover .front {
        transform: rotateX(180deg);
    }
    
    .cards-container-item:hover .back {
        transform: rotateX(0deg);
    }
}