@import '../../index.scss';

@keyframes zoomingBackgroundMaintenance {
    0% {
      background-size: 100% 100%;
    }
    50% {
      background-size: 120% 120%;
    }
    100% {
      background-size: 100% 100%;
    }
}

.brochure-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgb(203,200,45);
    background: radial-gradient(circle, rgba(203,200,45,1) 0%, rgba(57,165,175,1) 100%);
    padding: 0.4em 2em;
    margin-top: 2em;

    .brochure-banner-btn {
        @include reset-button-style;
        margin: 0;
        padding: 0.5em 1em;
        background-color:rgba(57, 165, 175, 0.9);
        color: white;
        margin-left: 10px;

        &:hover {
            box-shadow: 0 0 10px 2px #ff9900;
        }
    }
}
.property-features-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    line-height: 2em;
    margin-bottom: 2em;
    color: white;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 800px;
    background-attachment: fixed;
    background-image: url('../../assets/images/img_9_dark.jpg');
    animation: zoomingBackgroundMaintenance 10s ease-in-out infinite;

    @media screen and (max-width: 850px) {
        animation: none;
        transition: all 0.5s ease;
    }

    .property-features-wrapper-caption {
        width: 80%;
        text-align: center;
        margin-bottom: 3em;
        // font-family: 'Domine', serif;
        font-size: x-large;
        line-height: 2em;;

        .title-word {
            animation: color-animation 4s linear infinite;
            font-weight: bold;
        }
          
          .title-word-1 {
            --color-1: #DF8453;
            --color-2: #3D8DAE;
            --color-3: #E4A9A8;
        }
          
          .title-word-2 {
            --color-1: #DBAD4A;
            --color-2: #ACCFCB;
            --color-3: #CBC82D;
        }
          
          .title-word-3 {
            --color-1: #ACCFCB;
            --color-2: #E4A9A8;
            --color-3: #ACCFCB;
        }
    }
}

@keyframes color-animation {
    0%    {color: var(--color-1)}
    32%   {color: var(--color-1)}
    33%   {color: var(--color-2)}
    65%   {color: var(--color-2)}
    66%   {color: var(--color-3)}
    99%   {color: var(--color-3)}
    100%  {color: var(--color-1)}
}

.select-container {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    height: 300px;
    gap: 20px;


    &:nth-child(1) {
        margin-top: 20em;
    }

    .select-container-item{
        display: flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;
        position: relative;

        .select-container-element {
            height: 45px;
            padding-left: 1em;
            width: 300px;
            background-color:rgba(57, 165, 175, 0.7);
            border:none;
            border-radius: 5px;
            font-family: 'Open Sans', sans-serif;
            font-size: medium;
            color: inherit;

            &.disabled {
                color: grey;;
            }
        }
    }

    .packages-description {
        font-size: small;
        line-height: 1.5em;
        border-radius: 5px;
        width: 80%;

        .packages-description-item {
            &.bronze b{
                color: $bronze;
            }
            &.gold b{
                color: $gold;
            }
            &.platinum b{
                color: #3D8DAE;
            }
        }
    }
}

.quote-btn {
    @include reset-button-style;
    margin-bottom: 3em;
    padding: 1em;
    font-family: 'Open Sans', sans-serif;
    background-color:rgba(203, 200, 45, 0.7);
    color: white;
    
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.5) 0px 64px 75px, rgba(0, 0, 0, 0.22) 0px -16px 40px, rgba(0, 0, 0, 0.22) 0px 6px 8px, rgba(0, 0, 0, 0.27) 0px 16px 18px, rgba(0, 0, 0, 0.19) 0px -5px 7px;
        transform: scale(1.1);
    }
}

.offer-list-start {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s ease-out; 
  }
  
  .offer-list-start li {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .offer-list-start li.slide-in {
    opacity: 1;
    transform: translateX(0);
    display: flex;
    padding: 1em 1em;
    background-color: aliceblue;
    border-radius: 10px;
    transition: all 0.3s ease-out;
    padding-left: 2.5em;
    overflow: hidden;

    &:nth-of-type(2n) {
        background-color: rgba(203, 200, 45, 0.1);
    }
  }

  .offer-list-start li.slide-in .offer {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

.offer-list .offer::before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    background-image: url('../../assets/images/wrench-icon.png');
    background-size: cover;
    margin-right: 1em;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 4.5%;
}

.offer-list-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;

    ul {
        list-style: none;
        padding:0;
    
        @media screen and (max-width: 510px) {
            width: 80%;
            transition: all 0.5s ease;
        }
    
        li {
            display: flex;
            justify-content:baseline;
            align-items: center;
            margin: 0.5em;
            font-size: large;
        }
    
    }
}

.offer-list-header {
    font-size: xx-large;
    text-align: center;
    padding: 0 0.5em;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.error-message {
    font-size: small;
    color: #E4A9A8;
    position: absolute;
    bottom: -55%;
}

.quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    margin-bottom: 2em;

    .quote-container-price {
        margin-top: 6em;
        margin-left: 1em;
        margin-right: 1em;
        text-align: center;

        p {
            font-weight: bold;
            font-size: 1.3em;
            margin:0;
            padding: 1em;
            font-weight: bolder;
            @include header-animation;            
        }
    }
}

.quote-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .quote-buttons-item {
        @include reset-button-style;
        background-color: rgba(57, 165, 175, 0.7);
        padding: 0.5em 1em;
        color: white;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;

        &.back-btn {
            margin-left: 1em;
            background-color: rgba(203, 200, 45, 0.7);
        }

        &:hover {
            @include hover-btn;
        }
    }
}