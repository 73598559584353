@import '../../../index.scss';

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 30%;
  padding: 2em;
  font-family: 'Open Sans', sans-serif;

  @media screen and (max-width: 768px) {
    height: auto;
    flex-direction: column;
    transition: all 0.5s ease;
  }

  @media screen and (max-width: 600px) {
    height: auto;
    flex-direction: column;
    padding-top: 1em;
    transition: all 0.5s ease;
  }
}
  
.main-text-box {
  z-index: 1;
  font-size: xxx-large;
  color: white;
  width: 27%;
  border-top: 1px solid $colorM;
  border-bottom: 1px solid $colorM;
  text-align: left;
  margin-bottom: 1em;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
 
  @media screen and (max-width: 768px) {
    padding: 20px;
    width: 70%;
    transition: all 0.5s ease;
  }
  
  @media screen and (min-width: 769px) {
    width: 530px;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.5s ease;
  }

  @media screen and (max-height: 660px) {
    text-align: center;
    padding: 10px;
    transition: all 0.5s ease;
  }
  
  .main-text-box-header {

    @media screen and (max-height: 875px) {
      font-size: xx-large;
      transition: font-size 0.5s ease;
    }
  
    @media screen and (max-width: 535px) {
      font-size: xx-large;
      transition: font-size 0.5s ease;
    }

    @media screen and (max-height: 660px) {
      font-size: x-large;
      transition: font-size 0.5s ease;
    }

    @media screen and (max-height: 530px) {
      font-size: large;
      transition: all 0.5s ease;
    }
  }
}

.main-btn {
  z-index: 1;
  @include reset-button-style;
  margin: 0 auto;
  color: white;
  margin-bottom: 3em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  @media screen and (max-height: 530px) {
    font-size: small;
    transition: all 0.5s ease;
  }
}

.main-btn:hover {
  cursor: pointer;
  transform: translateY(-0.25em);
  box-shadow: 0 0.5em 0.5em -0.4em $color2;
}

