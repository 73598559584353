.privacy-policy-container {
    font-family: 'Open Sans', sans-serif;
    margin: 20px;

    header, section {
        margin-bottom: 20px;
    }
    h1, h2 {
        color: #007bff;
    }
    li {
        margin-bottom: 10px;
        line-height: 1.5em;
    }
}