@import '../../../index.scss';
.lead-popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    color: white;
    background-color: #318697;
    font-family: 'Comfortaa', cursive;

    display: flex;
    justify-content: center;
    width: 40em;
    height: 34em;

    @media screen and (max-width: 660px) {
        width: 20em;
        height: 30em;
        transition: all 0.3s ease;
    }

    @media screen and (max-height: 550px) {
        max-height: 90vh;
        overflow-y: auto;
        transition: all 0.3s ease;
    }

    .lead-popup-submitted {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3em;

        .lead-popup-submitted-img {
            height: 10em;
            width: 10em;
            @media screen and (max-height: 350px) {
                height: 5em;
                width: 5em;
                transition: all 0.3s ease;
            }
        }
    }

    .select-options-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .popup-header-1 {
            padding: 2em;
            text-align: center;
            line-height: 1.5em;
            text-transform: uppercase;
        }

        .popup-header-2 {
            color: white;
            padding: 1em;
            text-align: center;

            small {
                font-size: small;
            }

            h3 {
                line-height: 1.4em;
            }
        }

        h3 {
            @media screen and (max-width: 660px) {
                padding-top: 0.5em;
                text-align: center;
            }
        }

        .select-options-wrapper {
            background-color: #CBC82D;
            position: absolute;
            top: 13em;
            height: 19.8em;
            width: 30em;

            @media screen and (max-width: 660px) {
                position: static;
                width: 100%;
                height: 100%;
                background-color: white;
                transition: all 0.3s ease;
            }

            .select-options {
                height: 18.7em;
                background-color: white;
                padding-bottom: 1em;
                padding-left: 1em;
    
                -webkit-clip-path: polygon(0% 8%, 100% 0%, 100% 90%, 0% 99%);
                clip-path: polygon(0% 8%, 100% 0%, 100% 87%, 0% 99%);
    
                display: flex;

                @media screen and (max-width: 660px) {
                    justify-content: center;
                    padding-left: 0;
                    height: 100%;
                    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                    background-image: url('../../../assets/images/popup-img.jpg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: 70% 80%;
                    transition: all 0.3s ease;
                }
    
                .select-options-items {
                    display: flex;
                    flex-direction: column;
                    h4 {
                        color: black;
                        padding-top: 1em;

                        @media screen and (max-width: 660px) {
                            text-align: center;
                        }
                    }

                    .selectedRoleWidth {
                        width: 220px;
                    }
        
                    button {
                        @include reset-button-style;
                        border-radius: 5px;
                        margin: 0.5em;
                        padding: 0.5em;
                        background-color: white;
                        border: 1px solid #C7511F;
                        color: #C7511F;
                        font-family: 'Open Sans', sans-serif;

                        &:hover {
                            box-shadow: 0 0 5px 1px #ff9900;
                        }
                    }

                    .popup-form-inputs {
                        padding-top: 3em;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        position: relative;

                        .popup-selected-option {
                            color: black;
                            margin-bottom: 1em;
                            text-align: center;
                            font-weight: bold;
                        }

                        input {
                            padding: 0.8em;
                            border-radius: 5px;
                            margin-bottom: 0.5em;
                            border: 1px solid #238DC1;
                            font-family: inherit;
                            width: 220px;

                            &:disabled {
                                background-color: rgb(224, 224, 224);
                            }
                        }
                        .popup-red-border{
                            border: 2px solid #E4A9A8;
                            outline: none;
                            box-shadow: inset 0 0 4px #E4A9A8;
                        }

                        button {
                            background-color: #DBAD4A;
                            color: white;
                        }

                        .email-error {
                            font-size: small;
                            color: #E4A9A8;
                        }
                    }
                }
            }
        }
    }

    .popup-img-section {
        flex: 1.5;
        height: 544px;
        width: 100%;
        background-color: #318697;
        background-image: url('../../../assets/images/popup-img.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 70% 50%;
        -webkit-clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);

        @media screen and (max-width: 660px) {
            display: none;
            transition: all 0.3s ease;
        }

    }

    .close-btn {
        position: absolute;
        right: 0.3em;
        font-size: 30px;
        font-weight: 100;
        font-family: inherent;
        color: black;

        &:hover {
            cursor: pointer;
        }
    }

    @keyframes color-animation-lead-popup {
        0%    {color: var(--color-1)}
        32%   {color: var(--color-1)}
        33%   {color: var(--color-2)}
        65%   {color: var(--color-2)}
        66%   {color: var(--color-3)}
        99%   {color: var(--color-3)}
        100%  {color: var(--color-1)}
    }
}