.logo-container {
  height: 12em;
  display: flex;
  align-items: center;
  padding-top: 4em;
  padding-left: 5em;
  font-family: 'Open Sans', sans-serif;

  @media screen and (max-width: 690px) {
    height: 15em;
    flex-wrap: wrap;
    transition: all 0.5s ease;
  }

  @media screen and (max-width: 440px) {
    padding-left: 3em;
    transition: all 0.5s ease;
  }

  .logo{
    max-height: 8em;
    max-width: 22em;

    @media screen and (max-width: 500px) {
      max-height: 6em;
      max-width: 18em;
      transition: all 0.5s ease;
    }
  }

  p {
    margin-left: 2em;
    margin-right: 1em;
    font-family: 'Comfortaa', cursive;
  }

  .cp {
    max-height: 10em;
    max-width: 24em;
  }
}