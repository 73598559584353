.adminContainer {
    color: #ddd6cb;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .form {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .form label {
        display: block;
        margin-bottom: 0.5rem;
        font-size: 1rem;
        font-family: inherit;
        text-transform: uppercase;
        color: #000000;
        font-weight: bold;
    }

    .form input {
        display: block;
        width: 90%;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        border: 1px solid #454952;
        font-size: 1.25rem;
        font-family: inherit;
        color: #1f252d;
    }
    
    .form input:focus {
        outline-color: #39a5af;
        background-color: #ddd6cb;
    }
    
    .form button {
        border: 0;
        padding: 0.75rem 5rem;
        background: linear-gradient(30deg, #39a5af, #ffff92);
        border: none;
        color: #1f252d;
        border-radius: 2px;
        cursor: pointer;
        font: inherit;
        font-size: 1.25rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        margin-bottom: 2rem;
        margin-top: 2rem;
        transition: color 0.3s, box-shadow 0.3s;
    }
    
    .form button:hover,
    .form button:focus {
        color: #ffffff;
        box-shadow: 0 4px 10px rgba(255, 255, 255, 0.5);
    }

    .adminError {
        color: #ff403d;
        text-align: center;
    }
}