.recentPostsContainer {
    padding: 5rem;
    h2 {
        font-weight: bold;
        margin-bottom: 1.5em;
        font-size: 20px;
    }
    div {
        margin: 15px 0 15px 0;
    }
    .link {
        text-decoration: none;
        color: #238DC1;
    }
}