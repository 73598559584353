.review-spinner {
    height: 30vh;
}

.review-container {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    justify-content: center;
    min-height: 100vh;

    @media screen and (max-width: 800px) {
        width: 100%;
        transition: all 0.5s ease;
    }

    @media screen and (max-width: 1100px) {
        width: 85%;
        transition: all 0.5s ease;
    }

    .review-wrapper {
        display: flex;
        flex-direction: column;
        margin: 2em;
        width: 25%;

        @media screen and (max-width: 700px) {
            width: 90%;
            transition: all 0.5s ease;
        }

        @media screen and (max-width: 800px) {
            width: 70%;
            transition: all 0.5s ease;
        }
    }
}
